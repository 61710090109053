<template>
	<v-dialog v-model="show_bid_box" persistent width="auto" fullscreen transition="slide-fade" hide-overlay light>
		<v-form v-model="valid" ref="form" lazy-validation style="height: 100vh; margin-left: auto">
			<v-row no-gutters class="bid" style="background: rgba(0, 0, 0, 0.4)">
				<v-col
					cols="12"
					no-gutters
					class="pl-6 d-flex align-center bid-box ma-0"
					style="height: 65px; border-bottom: 1px solid #e7eaf3; margin: 0px -24px 0px -24px"
				>
					<v-btn v-on:click="closeBidBox" class="my-back-btn" depressed>
						<img :src="$store.state.icons.icons['LeftArrow']" class="back-btn" alt />
						Back
					</v-btn>
				</v-col>
				<v-col cols="12" lg="6" class="bid-box pa-0 d-flex flex-column" style="border-right: 1px solid #e7eaf3">
					<transition name="fade" mode="out-in">
						<div v-if="select_time_date.is_show" class="show-calender d-sm-flex align-center justify-end">
							<app-calendar v-model="select_time_date"></app-calendar>
						</div>
					</transition>
					<div class="px-6 pb-6 d-flex flex-column justify-space-between" style="position: relative; flex: 1">
						<div>
							<app-bid-header v-bind:value="bidHeader()"></app-bid-header>

							<div class="show-shadow col-lg-6 col-md-7 col-sm-9 col-12"></div>

							<app-list-interaction class="mb-6" v-if="bid_data.notes != null" v-model="bid_data.notes"></app-list-interaction>

							<v-row no-gutters class="display-time d-sm-flex d-block ma-0 px-0">
								<v-col cols="12" sm="6" class="pa-0 d-flex align-center">
									<div>
										<p class="heading-rf13 mb-1">Required delivery time:</p>
										<p v-if="bid_data.delivery_time === 'AS'" class="heading-rf15">As soon as possible</p>
										<p v-else class="heading-rf15">
											{{ dateFormat(bid_data.delivery_date) }}
											/ {{ deliveryTime() }}
										</p>
									</div>
								</v-col>
								<v-col cols="12" sm="6" class="mt-sm-0 mt-5 pa-0 d-flex align-center">
									<div class="pl-sm-6 d-flex flex-column">
										<div style="z-index: 7" class="d-flex flex-row align-sm-end mb-1">
											<img
												:src="$store.state.icons.icons['calendar']"
												style="height: 24px"
												class="mr-2"
												v-on:click="select_time_date.is_show = true"
											/>

											<div class="heading-rf13">Declare delivery time*:</div>
										</div>

										<p v-if="dateOfDelivery()" class="heading-rf15">
											{{ dateOfDelivery() }} /
											{{ timeOfDelivery() }}
										</p>
										<p v-else-if="this.error_message !== ''" class="p-0 custom-error text-center ma-0" :style="{ display: ShowMessage }">
											{{ this.error_message }}
										</p>

										<p v-else class="heading-rf15">- / -</p>
									</div>
								</v-col>
							</v-row>
						</div>
						<div class="mt-sm-0 mt-5 pa-0 d-lg-block d-none">
							<div class="bid-footer d-flex flex-column mt-auto">
								<div class="bid-footer-layer"></div>
								<app-show-bid-total v-model="calculateTotal" class="ma-0 overflow-hidden"></app-show-bid-total>
								<div class="pt-4 pb-3 d-flex">
									<p class="heading-sf18">Total:</p>
									<p class="heading-sf18 font-shadow ml-auto">
										{{ this.$options.filters.currency(total) }}
									</p>
								</div>
								<div v-if="$store.state.auth.error != ''" class="p-0 custom-error text-center pb-3">
									{{ this.$store.state.auth.error }}
								</div>
								<div class="mt-auto">
									<v-btn class="my-p-btn my_elevation" id="my_elevation" depressed block light @click="submit_bid" :disabled="Show"
										>Submit your quote</v-btn
									>
								</div>
							</div>
						</div>
					</div>
				</v-col>
				<v-col cols="12" lg="6" style="background: #ffffff; margin-left: auto" class="bid-box bid-box-height pa-0 d-flex flex-column">
					<div class="bid-items-content px-6">
						<app-bid-items v-model="bid_data" :listitems="listitems"></app-bid-items>
					</div>
				</v-col>
				<v-col class="d-lg-none d-block">
					<div class="mt-0 pa-0">
						<div class="bid-footer-layer"></div>
						<div class="bid-footer d-flex flex-column mt-auto pb-6 px-6">
							<app-show-bid-total v-model="calculateTotal" class="ma-0 overflow-hidden"></app-show-bid-total>
							<div class="pt-4 pb-3 d-flex">
								<p class="heading-sf18">Total:</p>
								<p class="heading-sf18 font-shadow ml-auto">
									{{ this.$options.filters.currency(total) }}
								</p>
							</div>
							<div v-if="$store.state.auth.error != ''" class="p-0 custom-error text-center pb-3">
								{{ this.$store.state.auth.error }}
							</div>
							<div class="mt-auto">
								<v-btn class="my-p-btn my_elevation" id="my_elevation" depressed block light @click="submit_bid" :disabled="Show"
									>Submit your quote</v-btn
								>
							</div>
						</div>
					</div>
				</v-col>
			</v-row>
		</v-form>
	</v-dialog>
</template>

<script>
import moment from 'moment'
const BidItems = () => import('./BidItemComponent')
const ShowBidTotal = () => import('./ShowBidTotalComponent')
const ListInteraction = () => import('./ListInteractionComponent')
const BidHeader = () => import('../bidcomponent/BidHeader')
import { bid } from '../../services/bidservice'
import { getList } from '../../services/listservice'
import { getShippingCharge } from '../../services/deliveryservice'
import { timeFormat, localDateFromDateTime } from '../../services/commonservice'
import firebase from 'firebase/app'
import { FIREBASE_COLLECTION_NAME_PREFIX } from '../../config/variable'
import { router } from '../../router'

export default {
	components: {
		AppBidItems: BidItems,
		AppShowBidTotal: ShowBidTotal,
		AppListInteraction: ListInteraction,
		AppBidHeader: BidHeader,
	},

	data() {
		return {
			list_id: '',
			show_bid_box: false,
			show: true,
			valid: true,
			is_disabled: false,
			isShowError: false,
			error_message: '',
			show_message: 'none',
			select_time_date: {
				date: '',
				time: '',
				start_time: '',
				end_time: '',
				requested_date: '',
				requested_time: '',
				is_show: false,
				is_validate: true,
			},
			bid_total: {
				sub_total: 0.0,
				shipping: 0.0,
				service_fee: 0.0,
				sales_tax: 0.0,
				tax_percentage: 0.0,
			},
			bid_data: {
				biditems: [],
			},
			listitems: [],
			notBlockedChatRoomId: [],
		}
	},

	watch: {
		show_bid_box() {
			if (this.show_bid_box === false) {
				Object.assign(this.$data, this.$options.data())
			}
		},
	},

	computed: {
		Show() {
			if (this.select_time_date.is_validate === false) {
				this.is_disabled = true
			} else if (this.$store.state.auth.error.length > 0) {
				this.is_disabled = true
			} else {
				this.is_disabled = !this.valid
				this.error_message = ''
			}
			return this.is_disabled
		},

		ShowMessage() {
			return (this.show_message = !this.error_message ? 'none' : 'block')
		},

		calculateTotal() {
			this.bid_total.sub_total = 0
			this.bid_total.service_fee = 0
			this.bid_total.sales_tax = 0
			this.bid_total.shipping = this.bid_data.shipping
			this.bid_data.biditems.forEach((item) => {
				this.bid_total.sub_total += Number(item.price) * Number(item.qty)
			})

			// this.bid_total.service_fee = ((this.bid_total.sub_total + this.bid_total.shipping) * 5) / 100
			this.bid_total.sales_tax =
				this.bid_data.tax_percentage != undefined
					? ((this.bid_total.sub_total + this.bid_total.shipping + this.bid_total.service_fee) * this.bid_data.tax_percentage) / 100
					: 0
			this.bid_data.sub_total = this.bid_total.sub_total
			this.bid_data.service_fee = this.bid_total.service_fee
			this.bid_data.sales_tax = this.bid_total.sales_tax
			this.bid_total.tax_percentage = this.bid_data.tax_percentage != undefined ? this.bid_data.tax_percentage : 0
			return this.bid_total
		},

		total() {
			let total = Number(this.bid_total.sub_total + this.bid_total.shipping + this.bid_total.service_fee + this.bid_total.sales_tax)
			return parseFloat(total).toFixed(2)
		},
	},

	methods: {
		closeBidBox() {
			if (this.$route.query.data) {
				this.$router.push('/lists')
			}
			this.show_bid_box = false
		},

		async bidSubmit(id) {
			this.$store.commit('errorMessage', '')
			this.show_bid_box = true
			this.list_id = id
			if (this.list_id === '') {
				this.show_bid_box = false
			}

			let userCollection = await firebase
				.firestore()
				.collection(FIREBASE_COLLECTION_NAME_PREFIX + 'messages')
				.where('isUserBlocked', '==', false)
			userCollection.onSnapshot((doc) => {
				this.notBlockedChatRoomId = []
				doc.forEach((doc) => {
					this.notBlockedChatRoomId.push(doc.id)
				})
			})

			await this.getList()
		},

		dateOfDelivery() {
			this.bid_data.start_time = this.select_time_date.start_time.length === 8 ? this.select_time_date.start_time : ''
			this.bid_data.end_time = this.select_time_date.end_time.length === 8 ? this.select_time_date.end_time : ''
			this.bid_data.time_of_delivery = this.select_time_date.time
			this.bid_data.date_of_delivery = this.select_time_date.date

			if (this.bid_data.start_time !== '' && this.bid_data.end_time !== '' && this.bid_data.time_of_delivery !== '' && this.bid_data.date_of_delivery) {
				return this.bid_data.date_of_delivery
			} else {
				if (this.isShowError === true && this.select_time_date.is_show === false) {
					this.error_message = 'Please select a valid delivery date and time.'
				}
				return false
			}
		},

		timeOfDelivery() {
			let st = this.bid_data.start_time
			let et = this.bid_data.end_time
			if (st != undefined && et != undefined) {
				return timeFormat(st) + ' - ' + timeFormat(et)
			}
		},

		bidHeader() {
			return {
				category_id: this.bid_data.category_id,
				list_id: this.bid_data.list_id,
				list_name: this.bid_data.list_name,
				list_lat: this.bid_data.list_lat,
				list_long: this.bid_data.list_long,
				// bid_lat: "",
				// bid_long: "",
				url: this.bid_data.url,
				idle_time: this.bid_data.idle_time,
				status: 'L',
				contractor: this.bid_data.contractor,
				address: this.bid_data.address,
			}
		},

		dateFormat(data) {
			return localDateFromDateTime(data)
		},

		deliveryTime() {
			let time = this.bid_data.delivery_time

			if (time === 'M') {
				return 'Morning'
			} else if (time === 'N') {
				return 'Noon'
			} else if (time === 'A') {
				return 'Afternoon'
			} else if (time === 'L') {
				return 'Last Stop'
			} else if (time === 'AS') {
				return 'As soon as possible'
			}
		},

		async submit_bid() {
			if (this.$refs.form.validate()) {
				if (
					this.bid_data.date_of_delivery === '' ||
					this.bid_data.time_of_delivery === '' ||
					this.bid_data.start_time === '' ||
					this.bid_data.end_time === ''
				) {
					this.$store.commit('errorMessage', 'Select delivery date and time.')
					this.select_time_date.is_validate = false
				} else {
					let currentTime = moment().format('YYYY-MM-DD HH:mm:ss')
					let deliveryTime = moment(this.bid_data.date_of_delivery + ' ' + this.bid_data.start_time).format('YYYY-MM-DD HH:mm:ss')

					if (currentTime > deliveryTime) {
						this.$store.commit('errorMessage', 'Delivery date and time must be grater than current date and time.')
						this.select_time_date.is_validate = false
					} else {
						this.$store.commit('errorMessage', '')
						this.error_message = ''
						let modification = this.bid_data.biditems.find((item) => item.modification === 'Y')
						this.bid_data.modification = modification === undefined ? 'N' : 'Y'
						// this.bid_data.date_of_delivery = covUTCDate(this.bid_data.date_of_delivery, this.bid_data.start_time)
						// this.bid_data.start_time = covUTCTime(this.bid_data.date_of_delivery, this.bid_data.start_time)
						// this.bid_data.end_time = covUTCTime(this.bid_data.date_of_delivery, this.bid_data.end_time)

						let data = await bid(this.bid_data)
						if (data.data !== null) {
							let data_ob = {
								list: data.data.clist,
								bid: data.data,
								contractor: {},
								notification: {},
							}
							this.$store.commit('bidTagStatus', {
								list_id: '',
								tag_status: '',
							})
							this.$store.commit('errorMessage', '')
							this.$store.commit('listReset')
							this.$store.commit('listData', data_ob)
						}

						if (data.data) {
							let collection_id = `${this.$store.state.auth.user_data.company_id}DC` + `${data.data.list_id}L`
							if (this.notBlockedChatRoomId.includes(collection_id)) {
								let userCollection = await firebase
									.firestore()
									.collection(FIREBASE_COLLECTION_NAME_PREFIX + 'messages')
									.doc(collection_id)
									.collection('chats')
									.where('bidobj.show_change_price', '==', true)
									.get()
								// userCollection.querySnapshot((doc) => {
								userCollection.forEach(async (doc) => {
									if (doc.id) {
										await firebase
											.firestore()
											.collection(FIREBASE_COLLECTION_NAME_PREFIX + 'messages')
											.doc(collection_id)
											.collection('chats')
											.doc(doc.id)
											.update({ 'bidobj.show_change_price': false })
									}
								})
								// })

								await firebase
									.firestore()
									.collection(FIREBASE_COLLECTION_NAME_PREFIX + 'messages')
									.doc(collection_id)
									.collection('chats')
									.add({
										image_url: '',
										message: 'Quote',
										chatRoomId: collection_id,
										time: Date.now(),
										sendBy: `${this.$store.state.auth.user_data.company_id}DC`,
										toSend: `${this.$store.state.chathaggle.contractor_id}C`,
										type: 0,
										docType: 'object',
										acceptRejectLable: '',
										bidobj: {
											bid_id: data.data.bid_id,
											biditems: data.data.biditems,
											clist: {
												list_id: data.data.list_id,
												list_name: data.data.clist.list_name,
											},
											discountPercentage: '',
											distributor_id: this.$store.state.auth.user_data.id,
											isBidAcceptedRejected: false,
											sub_total: data.data.sub_total,
											total_amount: data.data.total_amount,
											show_change_price: true,
											object_type: 'new_quote',
										},
										deleteBy: null,
										docUrl: '',
										docName: '',
										docSize: '',
									})

								let ref = await firebase
									.firestore()
									.collection(FIREBASE_COLLECTION_NAME_PREFIX + 'messages')
									.doc(collection_id)
									.get()
								let chat = await ref.data()
								if (chat.is_active_chat) {
									await firebase
										.firestore()
										.collection(FIREBASE_COLLECTION_NAME_PREFIX + 'messages')
										.doc(collection_id)
										.update({ is_active_chat: true })
								}
							}
							this.show_bid_box = false
							router.push('/home')
						}
					}
				}
			}
		},

		async getList() {
			let data
			if (this.$store.state.list.list_data.list.list_id != this.list_id) {
				let data_ob = await getList(this.list_id)
				data_ob = {
					list: data_ob.data,
					bid: {},
					contractor: {},
					notification: {},
				}
				this.$store.commit('listReset')
				this.$store.commit('listData', data_ob)
				data = JSON.parse(JSON.stringify(this.$store.state.list.list_data))
			} else {
				data = JSON.parse(JSON.stringify(this.$store.state.list.list_data))
			}
			this.bid_data.list_id = data.list.list_id
			this.bid_data.list_name = data.list.list_name
			this.bid_data.category_id = data.list.category_id
			this.bid_data.delivery_date = data.list.delivery_date
			this.bid_data.delivery_time = data.list.delivery_time
			this.bid_data.date_of_delivery = ''
			this.bid_data.time_of_delivery = ''
			this.bid_data.idle_time = data.list.idle_time
			this.bid_data.sub_total = 0
			this.bid_data.shipping = 0
			this.bid_data.service_fee = 0
			this.bid_data.sales_tax = 0
			this.bid_data.modification = 'N'
			this.bid_data.start_time = ''
			this.bid_data.end_time = ''
			this.bid_data.notes = data.list.notes
			this.bid_data.biditems = data.bid.biditems
			this.listitems = data.list.listitems
			this.bid_data.list_lat = data.list.latitude
			this.bid_data.list_long = data.list.longitude
			this.bid_data.url = data.list.url
			this.bid_data.contractor = data.contractor
			this.bid_data.address = data.list.address

			this.select_time_date.requested_date = this.bid_data.delivery_date
			this.select_time_date.requested_time = this.bid_data.delivery_time

			this.$store.commit('background')
			let shipping_charge = await getShippingCharge(this.bid_data.list_id)
			if (!shipping_charge) {
				this.error_message = this.$store.state.auth.error
			}
			this.bid_data.shipping = shipping_charge?.data?.shipping
			this.bid_data.tax_percentage = shipping_charge?.data?.sales_tax
			this.$store.commit('listReset')
		},
	},
}
</script>

<style lang="scss"></style>
